import "../styles/HelpChats.css";
import Help_telegram_logo from "../Images/Help_telegram_logo.svg";

import Help_vk_logo from "../Images/Help_vk_logo.svg";
const HelpSocials: React.FC = () => {
  return (
    <>
      <div
        className="help-main-container"
        style={{ marginBottom: "16px", paddingLeft: "16px" }}
      >
        <div className="logo-container">
          <a
            href="https://t.me/svyaznavahte"
            target="_blank"
            className="a-container"
            rel="noreferrer"
          >
            <img src={Help_telegram_logo} className="help-logo" alt="" />
          </a>
        </div>

        <div className="logo-container">
          <a
            href="https://vk.com/svyaznavahte"
            target="_blank"
            className="a-container"
            rel="noreferrer"
          >
            <img src={Help_vk_logo} className="help-logo" alt="" />
          </a>
        </div>
      </div>
    </>
  );
};
export default HelpSocials;
