import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import CollapseHelp from "../Components/CollapseHelp";
import HelpChats from "../Components/HelpChats";
import ChatwootButton from "../Components/ChatwootButton";
import CollapseHelpInstructions from "../Components/CollapseHelpInstructions";
import HelpSocials from "../Components/HelpSocials";
function HelpPage() {
  return (
    <div>
      <Header />
      <div className="wrapper">
        <div className="content">
          <div className="helpHeaderDiv">
            <img
              src="/Images/HelpPageIcon.svg"
              alt="helpimg"
              className="help-img"
            />
            <h1 className="Headline-Text">Помощь</h1>
          </div>
          <HelpChats />
          <div className="helpHeaderDiv">
            <img
              src="/Images/message-circle-question.svg"
              alt="helpimg"
              className="help-img"
            />
            <h1 className="Headline-Text">Часто задаваемые вопросы</h1>
          </div>

          <CollapseHelp />
          <div className="helpHeaderDiv">
            <img
              alt="helpimg"
              src="/Images/notepad-text.svg"
              className="help-img"
            />
            <h1 className="Headline-Text">Инструкции</h1>
          </div>
          <CollapseHelpInstructions />
          <div className="helpHeaderDiv">
            <img src="/Images/speech.svg" alt="helpimg" className="help-img" />
            <h1 className="Headline-Text">Социальные сети</h1>
          </div>
          <HelpSocials />
        </div>
      </div>
      <ChatwootButton />
      <Footer />
    </div>
  );
}

export default HelpPage;
