import { useEffect, useState } from "react";
import {
  getSecretKey,
  getLoginStorage,
  getSIGN,
} from "../../LocalStorage/Locals";
import CardBuyInfo from "../CardBuyInfo";
import CardBuy from "../CardBuy";
import "../../App.css";
import "../../styles/CardStyle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import SpinnerComponent from ".././SpinnerComponent";
import { getIP } from "../../api";
import tarifSvg from "../../Images/Tarrifs.svg";
type Reccommendations = {
  code: string;
  units: number;
  title: string;
  description: string;
  type: string;
  bgcolor: string;
  typename: string;
};

export default function RecSlider() {
  const [swiperParams, setSwiperParams] = useState({
    slidesPerView: 2,
    spaceBetween: 20,
  });
  const updateSwiperParams = () => {
    setSwiperParams({
      slidesPerView:
        window.innerWidth <= 710 ? 1 : window.innerWidth >= 1110 ? 3 : 2,
      spaceBetween: 0,
    });
  };

  useEffect(() => {
    updateSwiperParams();
    window.addEventListener("resize", updateSwiperParams);
    return () => {
      window.removeEventListener("resize", updateSwiperParams);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [Items, setItems] = useState<Reccommendations[]>([]);
  const login = getLoginStorage();
  const secretKey = getSecretKey();
  const SIGN = getSIGN();
  const fetchItemData = async () => {
    try {
      setIsLoading(true);
      const ip = await getIP();
      await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_RECOMMENDATIONS&login=" +
          login +
          "&KEY=" +
          secretKey +
          "&SIGN=" +
          SIGN +
          `&IP=${ip || ""}`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setItems(data.recommendations);
        });
      setIsLoading(false);
    } catch (error) {
      console.log("Error", error);

      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchItemData();
  }, []);

  return (
    <div>
      {isLoading && <SpinnerComponent />}

      {Items[0] == null ? (
        <p className="text-tariftitle"></p>
      ) : (
        <div>
          {" "}
          <p className="Headline-Text">
            <img src={tarifSvg} className="user-acc-svg" /> Рекомендации
          </p>
          <div className="card-container">
            <Swiper
              {...swiperParams}
              modules={[Pagination]}
              pagination={{
                clickable: true,
              }}
            >
              {Items.map((item) => (
                <SwiperSlide key={item.code}>
                  <div>
                    <div
                      key={item.code}
                      className="custom-card-3"
                      style={{
                        backgroundImage: item.bgcolor
                          ? `linear-gradient(to right, #304782, ${item.bgcolor})`
                          : `linear-gradient(to right, #304782, #304782)`,
                        maxWidth: "350px",
                      }}
                    >
                      <div
                        className="flex-row"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="flex-row">
                          <p className="text">Номинал</p>
                          <p className="text-nominal">{item.units}</p>
                        </div>
                        <div className="flex-row">
                          <p className="text-promotion">{item.typename}</p>
                        </div>
                      </div>
                      <div className="flex-row ">
                        <div className="flex-col">
                          <p className="text-tariftype">{item.type}</p>
                          <p className="text-tariftitle ">{item.title}</p>
                        </div>
                      </div>
                      <div className="flex-row-buttons">
                        <CardBuyInfo
                          name={item.title}
                          type={item.type}
                          code={item.code}
                          descr={item.description}
                        />
                        <CardBuy code={item.code} name={item.title} rec="rec" />
                      </div>
                    </div>
                  </div>

                  <div className="blank-space-traffic" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
}
